import React from "react";
import "./InfoPanel.css";
import { Button } from "@amzn/stencil-react-components/button";
import {
  Col,
  Container,
  Row,
  View,
} from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { IconCopy } from "@amzn/stencil-react-components/icons";

import {
  StatusIndicator,
  STATUS,
} from "@amzn/stencil-react-components/status-indicator";
import CcpService from "../../services/CcpService";

interface InfoPanelProps {
  ccpService: CcpService;
  active: boolean;
}

export default class InfoPanel extends React.Component<InfoPanelProps> {
  constructor(props: InfoPanelProps) {
    super(props);
    this.getVerificationStatus = this.getVerificationStatus.bind(this);
    this.getPinValue = this.getPinValue.bind(this);
  }

  copyPhoneNumber() {
    /* Get the text field */
    var copyText = document.getElementById("phoneNumber");

    if (copyText?.textContent) {
      const el = document.createElement("textarea");
      el.value = copyText.textContent;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      console.log("Copied");
    }
  }

  getVerificationStatus() {
    console.log("Getting verification status");
    if (window.CCP.contact) {
      let attributes = window.CCP.contact.getAttributes();
      let verificationStatus = attributes["IDVResult"];

      console.log("Verification status ", verificationStatus);
      if (verificationStatus) {
        if (verificationStatus.value === "Passed") {
          return (
            <StatusIndicator
              statusIcon={STATUS.POSITIVE}
              statusLabel={verificationStatus.value}
              textColor="green70"
              size="l"
            />
          );
        } else {
          return (
            <StatusIndicator
              statusIcon={STATUS.NEGATIVE}
              statusLabel={verificationStatus.value}
              textColor="red70"
              size="l"
            />
          );
        }
      }
    }
  }

  getPinValue() {
    console.log("Getting pin status");
    if (window.CCP.contact) {
      let attributes = window.CCP.contact.getAttributes();
      let pinValue = attributes["IDVCallbackPIN"];

      console.log("pin status ", pinValue);
      if (pinValue) {
        if (pinValue.value !== "Expired") {
          return (
            <>
              <Text textAlign="center">Security PIN</Text>
              <Text textAlign="center">{pinValue.value}</Text>
            </>
          );
        } else {
          return (
            <>
              <Text textAlign="center">Security PIN</Text>
              <Text textAlign="center" color="red70">
                {pinValue}
              </Text>
            </>
          );
        }
      }
    }
  }

  getPhoneNumber() {
    if (window.CCP.contact.getType() !== "chat") {
      if (window.CCP.contact) {
        if (
          window.CCP.contact.getActiveInitialConnection() &&
          window.CCP.contact.getActiveInitialConnection().getEndpoint() &&
          window.CCP.contact.getActiveInitialConnection().getEndpoint()
            .phoneNumber
        ) {
          return (
            <>
              <Text textAlign="center">Copy Incoming Number</Text>
              <Row
                justifyContent="space-between"
                backgroundColor="#d4e4e6"
                gridGap={32}
                padding={8}
                alignItems="center"
              >
                <Button iconOnly onClick={() => this.copyPhoneNumber()}>
                  <Row gridGap={8} alignItems="center">
                    <IconCopy title="Copy" />
                  </Row>
                </Button>
                <Text id="phoneNumber">
                  {
                    window.CCP.contact
                      .getActiveInitialConnection()
                      .getEndpoint().phoneNumber
                  }
                </Text>
              </Row>
            </>
          );
        } else {
          return (
            <>
              <Text id="phoneNumber">"Unknown number"</Text>
            </>
          );
        }
      }
    }
  }

  render() {
    return (
      <>
        <View
          backgroundColor="neutral90"
          padding={{ top: 2, bottom: 2, left: 2, right: 2 }}
        >
          <View
            backgroundColor="#d4e4e6"
            padding={{ top: 16, bottom: 16, left: 16, right: 16 }}
            justifyContent="center"
          >
            <Col gridGap="16px">
              <Text textAlign="center">Security Pass/Fail</Text>
              <View display="flex" justifyContent="center">
                {this.getVerificationStatus()}
              </View>
              {this.getPinValue()}
              {this.getPhoneNumber()}
            </Col>
          </View>
        </View>
      </>
    );
  }
}
