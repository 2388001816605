import React from "react";
import "./App.css";
import { Col, Container, View } from "@amzn/stencil-react-components/layout";
import CcpService from "../services/CcpService";
import Header from "../components/header/Header";
import InfoPanel from "./info-panel/InfoPanel";
import { Text } from "@amzn/stencil-react-components/text";
import { Link } from "@amzn/stencil-react-components/link";

interface AppProps {
  name: string;
}

interface AppState {
  update: boolean;
  ccpWidth: number;
  ccpHeight: number;
  latencyTest: boolean;
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      update: false,
      ccpWidth: 433,
      ccpHeight: 578,
      latencyTest: false,
    };
  }

  updateApp = (value: boolean) => {
    console.log("updating ");

    try {
      this.setState({ update: value });
    } catch (e) {
      console.log(e);
    }
  };

  updateSize = (isActive: boolean) => {
    if (isActive === false) {
      this.setSmallSize();
    }
    if (isActive === true) {
      this.setLargeSize();
    }
  };

  ccpService: CcpService = new CcpService(this.updateApp);

  componentDidMount() {
    //this.ccpService.initialize();
    setTimeout(() => {
      window.location.href = this.getUrl();
    }, 8000);
  }

  getInfoPanel() {
    if (this.state.update) {
      return (
        <InfoPanel
          ccpService={this.ccpService}
          active={this.state.update}
        ></InfoPanel>
      );
    } else {
      return <></>;
    }
  }

  setSmallSize() {
    this.setState({ ccpWidth: 433, ccpHeight: 578 });
  }

  setLargeSize() {
    this.setState({ ccpWidth: 600, ccpHeight: 800 });
  }

  getUrl() {
    if (
      process.env.REACT_APP_ENVIRONMENT === "dev" ||
      process.env.REACT_APP_ENVIRONMENT === "local"
    ) {
      return "https://beta.ccp.estech-connect.pxt.amazon.dev/amer";
    } else if (process.env.REACT_APP_ENVIRONMENT === "stg") {
      return "https://gamma.ccp.estech-connect.pxt.amazon.dev/amer";
    } else {
      return "https://ccp.estech-connect.pxt.amazon.dev/amer";
    }
  }

  render() {
    return (
      <>
        <Header updateSize={this.updateSize}></Header>
        <View margin={[52, 0, 0, 0]}>
          <Container backgroundColor="neutral00" fullWidth={true}>
            <View
              height="100%"
              width="100%"
              backgroundColor="neutral00"
              padding={128}
            >
              <Col gridGap={16} display="flex">
                <Text fontSize="T500" textAlign={"center"}>
                  This application has moved. You will be redirected to the new
                  location. Please bookmark the new link once redirected. If
                  automatic redirection does not occur, you may access the new
                  location <Link href={this.getUrl()}>here</Link>.
                </Text>
              </Col>
            </View>
          </Container>
          {/* <Row gridGap={32} padding={8} alignItems="center">
            <View
              backgroundColor="neutral90"
              padding={[2, 2, 2, 2]}
              height={this.state.ccpHeight}
              width={this.state.ccpWidth}
              id="container-div"
            ></View>
            {this.getInfoPanel()}
          </Row> */}
        </View>
      </>
    );
  }
}
