import React from "react";
import "./Header.css";
import { Button } from "@amzn/stencil-react-components/button";
import {
  Col,
  Container,
  Row,
  Spacer,
} from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import {
  PopoverPosition,
  PopoverController,
} from "@amzn/stencil-react-components/popover";
import { IconMenu } from "@amzn/stencil-react-components/icons";

interface HeaderProps {
  updateSize: any;
}

interface HeaderState {
  isMenuOpen: boolean;
}
export default class Header extends React.Component<HeaderProps, HeaderState> {
  updateCcpSize: any;
  popoverRef: any;

  constructor(props: any) {
    super(props);
    this.updateCcpSize = this.props.updateSize;
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.popoverRef = React.createRef();

    this.state = { isMenuOpen: false };
  }

  toggle = () => {
    this.setState({ isMenuOpen: true });
  };

  close = () => {
    this.setState({ isMenuOpen: false });
  };

  render() {
    return (
      <Container
        fullWidth={true}
        paddingHorizontal={0}
        paddingBottom={0}
        paddingTop={0}
        className="header_container"
      >
        <Row
          justifyContent="space-between"
          backgroundColor="#232f3e"
          gridGap={32}
          padding={8}
          alignItems="center"
        >
          <Text color="white" fontSize="T400">
            Employee Resource Center CCP
          </Text>
          <Spacer flex={1} />

          {/* <ToggleSwitch
            label="CCP Size"
            labelPosition="left"
            iconActive={IconPencil}
            iconInactive={IconPhone}
            onChange={this.updateCcpSize}
          /> */}

          <Button
            tertiary
            iconOnly
            inverseColor="white"
            onClick={this.toggle}
            ref={this.popoverRef}
          >
            <IconMenu title="Open menu" />
          </Button>

          <PopoverController
            target={this.popoverRef}
            isOpen={this.state.isMenuOpen}
            close={this.close}
            position={PopoverPosition.BottomCenter}
            shouldCloseOnFocusLeave={false}
          >
            <Col padding="S300" gridGap="S500" alignItems="flex-start">
              <Text fontSize="T200">
                Environment: {process.env.REACT_APP_ENVIRONMENT}
              </Text>
              <Button tertiary onClick={this.close}>
                Close
              </Button>
            </Col>
          </PopoverController>
        </Row>
      </Container>
    );
  }
}
