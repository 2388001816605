import "amazon-connect-streams";
import "amazon-connect-chatjs";

declare global {
  interface Window {
    CCP: any;
  }
}
window.CCP = window.CCP || {};

export default class CcpService {
  ccpUrl: string;
  containerDiv: any;
  update: any;

  constructor(updateApp: any) {
    this.update = updateApp.bind(this);
    this.ccpUrl = process.env.REACT_APP_CCP_URL!;
    this.initialize = this.initialize.bind(this);
    this.subscribeToContactEvents = this.subscribeToContactEvents.bind(this);
    this.subscribeToAgentEvents = this.subscribeToAgentEvents.bind(this);
  }

  initialize() {
    console.log("Initializing");

    this.containerDiv = document.getElementById("container-div");

    if (!(window.connect.core as any).initialized) {
      let ccpParams: any = {
        ccpUrl: this.ccpUrl,
        loginPopup: true,
        loginPopupAutoClose: true,
        softphone: {
          allowFramedSoftphone: true,
        },
        region: "us-east-1",
      };

      if (
        process.env.REACT_APP_SAML_URL &&
        process.env.REACT_APP_SAML_URL !== "undefined" &&
        process.env.REACT_APP_SAML_URL !== ""
      ) {
        ccpParams.loginUrl = process.env.REACT_APP_SAML_URL;
      }

      connect.core.initCCP(this.containerDiv, ccpParams);
    }

    connect.contact(this.subscribeToContactEvents);
    connect.agent(this.subscribeToAgentEvents);
  }

  // Contact Events
  subscribeToContactEvents(contact: any) {
    window.CCP.contact = contact;

    try {
      this.update(true);
    } catch (e) {
      console.log(e);
    }

    console.log("[Connnect Init] Subscribing to events for contact");

    try {
      if (
        contact.getActiveInitialConnection() &&
        contact.getActiveInitialConnection().getEndpoint()
      ) {
        console.log(
          "[Connect Init] New contact is from " +
            contact.getActiveInitialConnection().getEndpoint().phoneNumber
        );
      } else {
        console.log(
          "[Connect Init] This is an existing contact for this agent"
        );
      }

      console.log(
        "[Connect Init] Contact is from queue " + contact.getQueue().name
      );
      console.log(
        "[Connect Init] Contact attributes are " +
          JSON.stringify(contact.getAttributes())
      );
    } catch (e) {
      console.log(e);
    }

    contact.onIncoming(this.handleContactIncoming);
    contact.onAccepted(this.handleContactAccepted);
    contact.onConnected(this.handleContactConnected);
    contact.onEnded(this.handleContactEnded);
  }

  handleContactIncoming(contact: any) {
    if (contact) {
      console.log(
        "[contact.onIncoming] Contact is incoming. Contact state is " +
          contact.getStatus().type
      );
    } else {
      console.log("[contact.onIncoming] Contact is incoming. Null contact");
    }
  }

  handleContactAccepted(contact: any) {
    if (contact) {
      console.log(
        "[contact.onAccepted] Contact accepted by agent. Contact state is " +
          contact.getStatus().type
      );
    } else {
      console.log(
        "[contact.onAccepted] Contact accepted by agent. Null contact passed to event handler"
      );
    }
  }

  handleContactConnected(contact: any) {
    if (contact) {
      console.log(
        "[contact.onConnected] Contact connected to agent. Contact state is " +
          contact.getStatus().type
      );
    } else {
      console.log(
        "[contact.onConnected] Contact connected to agent. Null contact passed to event handler"
      );
    }
  }

  handleContactEnded(contact: any) {
    try {
      if (contact) {
        console.log(
          "[contact.onEnded] Contact has ended. Contact state is " +
            contact.getStatus().type
        );
      } else {
        console.log(
          "[contact.onEnded] Contact has ended. Null contact passed to event handler"
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Agent Events
  subscribeToAgentEvents(agent: any) {
    window.CCP.agent = agent;

    window.CCP.agent.update = this.update;

    try {
      this.update(false);
    } catch (e) {
      console.log(e);
    }

    console.log(
      "[Connect Init] Subscribing to events for agent " + agent.getName()
    );
    console.log(
      "[Connect Init] Agent is currently in status of " + agent.getStatus().name
    );

    agent.onError(this.handleAgentError);

    // On Softhphone Error Event
    agent.onSoftphoneError((error: any) => {
      console.log("[agent.onSoftphoneError] Agent softphone error ", error);
    });

    agent.onRefresh(this.handleAgentRefresh);
    agent.onRoutable(this.handleAgentRoutable);
    agent.onNotRoutable(this.handleAgentNotRoutable);
    agent.onOffline(this.handleAgentOffline);
    agent.onWebSocketConnectionLost(this.handleAgentWebSocketConnectionLost);
    agent.onStateChange(this.handleAgentStateChange);
  }

  handleAgentError(agent: any) {
    console.log(agent);
  }

  handleAgentRefresh(agent: any) {
    console.log(
      "[agent.onRefresh] Agent data refreshed. Agent status is " +
        agent.getStatus().name
    );
  }

  handleAgentRoutable(agent: any) {
    try {
      window.CCP.agent.update(false);
    } catch (e) {
      console.log(e);
    }
    console.log(
      "[agent.onRoutable] Agent is routable. Agent status is " +
        agent.getStatus().name
    );
  }

  handleAgentNotRoutable(agent: any) {
    console.log(
      "[agent.onNotRoutable] Agent is online, but not routable. Agent status is " +
        agent.getStatus().name
    );
  }

  handleAgentOffline(agent: any) {
    try {
      window.CCP.agent.update(false);
    } catch (e) {
      console.log(e);
    }
    console.log(
      "[agent.onOffline] Agent is offline. Agent status is " +
        agent.getStatus().name
    );
  }

  handleAgentWebSocketConnectionLost(agent: any) {
    console.log(
      "[agent.onOffline] Web Socket Connection Lost " + agent.getStatus().name
    );
  }

  handleAgentStateChange(agentStateChange: any) {
    console.log(agentStateChange);
    console.log("[agent.stateChange] State Change");
  }
}
